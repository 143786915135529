<template>
  <div id="profile-transaction-info" class="profile-transaction-info mb-3">
    <!-- Transaction -->
    <router-link to="/recent-order?status=allpaid">
      <div>Transaction</div>
      <div>{{ info.transaction_count }}</div>
    </router-link>
    <!-- Sales -->
    <router-link to="/recent-order?status=allpaid">
      <div>Order Journey</div>
      <div>{{ $helpers.format.price(info.totalTransaction) }}</div>
    </router-link>
    <!-- Referral -->
    <!-- <router-link to="/bus-partner-referral" v-if="resellerData && resellerData.is_active && resellerData.status_verify">
      <div>Referral</div>
      <div>{{ info.referrer_count }}</div>
    </router-link> -->
    <!-- Commission -->
    <!-- <router-link to="/bus-partner-commission" v-if="resellerData && resellerData.is_active && resellerData.status_verify">
      <div>Commission</div>
      <div>{{ $helpers.format.price(info.reseller_commission) }}</div>
    </router-link> -->
    <!-- Lizpoint -->
    <router-link to="/my-liz-point">
      <div>Lizpoint</div>
      <div>{{ $helpers.format.price(info.total_points) }}</div>
    </router-link>
  </div>
</template>

<script>

export default {
  name: "TransactionInfo",
  props: ["userData", "resellerData"],
  computed: {
    info() {
      return this.userData.additional_info
    }
  },
};
</script>