<template>
  <div class="profile-navbar-info mb-3">
    <div class="position-relative">
      <div class="profile-img-container">
        <img src="@/assets/img/my-profile-christmas.png" alt="Wizzy" v-if="isCustomTheme" />
        <img src="@/assets/img/my-profile-default.png" alt="Wizzy" v-else />
        <!-- <img src="@/assets/img/my-profile-default.png" alt="Wizzy" /> -->
      </div>
      <div v-if="resellerData && resellerData.is_active && resellerData.status_verify" class="reseller-verified-status"></div>
    </div>
    <div class="profile-text-info ml-3">
      <p class="name">{{ userData.full_name }}</p>
      <p>{{ userData.email }}</p>
      <p>{{ userData.phone }}</p>
    </div>  
  </div>
</template>

<script>
export default {
  name: "ProfileInfo",
  props: ["userData", "resellerData"],
  computed: {
    isCustomTheme() {
      return this.$store.getters.getWhizlizTheme == 'CHRISTMAS';
    },
  },
};
</script>