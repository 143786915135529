<template>
  <div :id="visible !== undefined ? 'profile-navbar-window' : ''" class="profile-navbar" :class="{'col-md-3 p-0 pt-5 d-none d-md-block' : visible === undefined}">
    <fawesome-pro variant="far" icon="arrow-left" size="lg" class="d-md-none w-100 p-3 text-right" v-b-toggle.sidebar-profile />
    <div class="container-fluid">
      <div class="breadcrumb d-none d-lg-block">
        <a href="#" @click="$router.push('/')">Home</a>
        <i class="icon-next"></i>
        <span>My Account</span>
      </div>
      <div class="border-bottom mb-3">
        <ProfileInfo :userData="userData" :resellerData="resellerData"/>
        <!-- <ResellerJourney :resellerData="resellerData" v-if="resellerData && isMembershipActive"/>
        <ResellerTelegramLink v-if="resellerData && isMembershipActive"/>
        <ResellerMembershipDowngrade v-if="resellerData && isMembershipActive" /> -->
        <TransactionInfo :userData="userData" :resellerData="resellerData"/>
        <!-- Register BP Button -->
        <!-- <b-button
          id="button-register-reseller"
          block   
          class="btn-wz-proceed"
          v-b-modal.modal-register-reseller
          v-if="!resellerData || (resellerData && !isMembershipActive)"
        >
          Become a Business Partner
        </b-button> -->
      </div>
    </div>
    <ul class="side-menu mb-0 pb-md-5 list-unstyled">
      <!-- Navbar For Reseller Only START -->
      <!-- <li class="bg-grey" id="reseller-menu" v-if="resellerData">
        <a href="javascript:void(0)" class="d-flex align-items-center caret-active" @click="openGroup">
          <img
            class="idle w28h28"
            src="@/assets/icons/icon_BP_home_fix.svg"
            alt=""
          />
          <img
            class="act w28h28"
            src="@/assets/icons/icon_BP_home_fix.svg"
            alt=""
          />
          <span class="side-menu-item">Business Partner</span>
          <fawesome-pro icon="caret-down" variant="fas" class="ml-auto" />
        </a>
        <ul class="list-unstyled shadow submenu">
          <li id="reseller-level" v-if="isMembershipActive">
            <router-link
              to="/bus-partner-level"
              class="d-flex align-items-center"
            >
              <img
                height="24"
                class="idle"
                src="@/assets/icons/icon_whizsquad_fix.svg"
                alt=""
              />
              <img
                height="24"
                class="act"
                src="@/assets/icons/icon_whizsquad_fix_hover_on.svg"
                alt=""
              />
              <span class="side-menu-item">WHIZQUAD</span>
            </router-link>
          </li>
          <li id="reseller-task" v-if="isMembershipActive">
            <router-link
              to="/bus-partner-task"
              class="d-flex align-items-center"
            >
              <img
                height="24"
                class="idle"
                src="@/assets/icons/icon_whi_activity.svg"
                alt=""
              />
              <img
                height="24"
                class="act"
                src="@/assets/icons/icon_whi_activity_hover_on.svg"
                alt=""
              />
              <span class="side-menu-item">Whiz Activity</span>
            </router-link>
          </li>
          <li id="reseller-manage-catalog" v-if="isMembershipActive">
            <div class="d-flex align-items-center">
              <router-link
                to="/manage-catalog"
                class="d-flex align-items-center"
              >
                <img
                  height="24"
                  class="idle"
                  src="@/assets/img/icon-product-catalog.svg"
                  alt=""
                />
                <img
                  height="24"
                  class="act"
                  src="@/assets/img/icon-product-catalog-hover.svg"
                  alt=""
                />
                <span class="side-menu-item">Product Catalog</span>
              </router-link>
              <img
                height="16" class="ml-auto mr-0 cursor-pointer" src="@/assets/img/share_regular.svg" alt="" @click="shareCatalog()" />
            </div>
          </li>
          <li id="reseller-membership">
            <router-link
              to="/bus-partner-membership"
              class="d-flex align-items-center"
            >
              <img
                height="24"
                class="idle"
                src="@/assets/img/icon-my-membership.svg"
                alt=""
              />
              <img
                height="24"
                class="act"
                src="@/assets/img/icon-my-membership-hover.svg"
                alt=""
              />
              <span class="side-menu-item">Membership Status</span>
            </router-link>
          </li>
          <li id="reseller-commission" v-if="isMembershipActive">
            <router-link
              to="/bus-partner-commission"
              class="d-flex align-items-center"
            >
              <img height="24" class="idle" src="@/assets/img/icon-commission.svg" alt="" />
              <img
                height="24"
                class="act"
                src="@/assets/img/icon-commission-hover.svg"
                alt=""
              />
              <span class="side-menu-item">Commission History</span>
            </router-link>
          </li>
          <li id="reseller-order-report" v-if="isMembershipActive">
            <router-link
              to="/bus-partner-sales-order"
              class="d-flex align-items-center"
            >
              <img
                height="24"
                class="idle"
                src="@/assets/img/icon-transaction-report.svg"
                alt=""
              />
              <img
                height="24"
                class="act"
                src="@/assets/img/icon-transaction-report-hover.svg"
                alt=""
              />
              <span class="side-menu-item">Order Report</span>
            </router-link>
          </li>
          <li id="reseller-referral" v-if="isMembershipActive">
            <router-link
              to="/bus-partner-referral"
              class="d-flex align-items-center"
            >
              <img height="24" class="idle" src="@/assets/img/icon-referral.svg" alt="" />
              <img
                height="24"
                class="act"
                src="@/assets/img/icon-referral-hover.svg"
                alt=""
              />
              <span class="side-menu-item">My Referral</span>
            </router-link>
          </li>
        </ul>
      </li> -->
      <li id="recent-order">
        <router-link to="/recent-order" class="d-flex align-items-center">
          <img class="idle w28h28" src="@/assets/img/recent-order.svg" alt="" />
          <img class="act w28h28" src="@/assets/img/recent-order-hover.svg" alt="" />
          <span class="side-menu-item">Recent Order</span>
        </router-link>
      </li>
      <li id="liz-points">
        <router-link to="/my-liz-point" class="d-flex align-items-center">
          <img class="idle w28h28" src="@/assets/img/liz-point.svg" alt="" />
          <img class="act w28h28" src="@/assets/img/liz-point-hover.svg" alt="" />
          <span class="side-menu-item">My Liz-Points</span>
        </router-link>
      </li>
      <li id="profile-review">
        <router-link to="/product-review" class="d-flex align-items-center">
          <img class="idle w28h28" src="@/assets/img/your-review.svg" alt="" />
          <img class="act w28h28" src="@/assets/img/your-review-hover.svg" alt="" />
          <span class="side-menu-item">My Reviews</span>
        </router-link>
      </li>
      <li class="bg-grey" id="settings">
        <a href="javascript:void(0)" class="d-flex align-items-center" :class="{'caret-active' : showConfig}" @click="showConfig = !showConfig">
          <i class="icon-setting"></i>
          <span class="side-menu-item pl-2 ml-1">Settings</span>
          <fawesome-pro icon="caret-down" variant="fas" class="ml-auto" />
        </a>
        <ul class="list-unstyled shadow submenu">
          <li id="edit-profile">
            <router-link to="/edit-profile" class="d-flex align-items-center">
              <img class="idle" height="24" src="@/assets/img/edit-profile.svg" alt="" />
              <img class="act" height="24" src="@/assets/img/edit-profile-hover.svg" alt="" />
              <span class="side-menu-item">Edit Profile</span>
            </router-link>
          </li>
          <li id="change-password">
            <router-link to="/change-password" class="d-flex align-items-center">
              <img class="idle" height="24" src="@/assets/img/change-password.png" alt="" />
              <img class="act" height="24" src="@/assets/img/change-password-hover.png" alt="" />
              <span class="side-menu-item">Change Password</span>
            </router-link>
          </li>
          <li id="my-address">
            <router-link to="/my-address" class="d-flex align-items-center">
              <img class="idle" height="24" src="@/assets/img/my-address.svg" alt="" />
              <img class="act" height="24" src="@/assets/img/my-address-hover.svg" alt="" />
              <span class="side-menu-item">My Address</span>
            </router-link>
          </li>
          <li id="logout-all">
            <a href="#" v-b-modal.modal-logout-all class="d-flex align-items-center">
              <img class="idle" height="24" src="@/assets/img/logout.svg" alt="" />
              <img class="act" height="24" src="@/assets/img/logout-hover.svg" alt="" />
              <span class="side-menu-item">Logout Other Devices</span>
            </a>
          </li>
        </ul>
      </li>
      <!-- Navbar For Reseller Only END -->
      <li class="bg-grey" id="logout">
        <a href="#" @click="logout()" class="d-flex align-items-center">
          <img class="idle w28h28" src="@/assets/img/logout.svg" alt="" />
          <img class="act w28h28" src="@/assets/img/logout-hover.svg" alt="" />
          <span class="side-menu-item">Logout</span>
        </a>
      </li>
    </ul>
    <b-modal
      id="modal-logout-all"
      modal-class="p-0"
      centered
      hide-header
      @ok="logoutAll()"
    >
      <div class="modal-dialog" role="document" style="display: contents">
        <div class="modal-content" style="display: contents">
          <div class="modal-header">
            <div class="title-address" id="exampleModalLabel">Warning</div>
            <button
              type="button"
              class="close"
              data-dismiss="modal"
              aria-label="Close"
              @click="$bvModal.hide('modal-logout-all')"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body p-0 pt-3">
            <p>Are you sure you want to sign out from all other devices?</p>
          </div>
        </div>
      </div>
    </b-modal>
    <!-- introduction for profile navbar using vue tour -->
    <VueTour :tourName="tourName" :steps="steps" :callbacks="callbacks" :options="options" :ref="tourName" />
  </div>
</template>

<script>
import { toaster, cookieMan, scroll, mobile, tourSettings } from "@/_helpers";
import { userService, qbHelper } from "@/_services";
import VueTour from "@/components/vuetour/VueTour.vue";
import ProfileInfo from "@/components/profileNavbar/ProfileInfo.vue"
// import ResellerJourney from "@/components/profileNavbar/ResellerJourney.vue"
import TransactionInfo from "@/components/profileNavbar/TransactionInfo.vue"
// const ResellerTelegramLink = () => import("@/components/profileNavbar/ResellerTelegramLink.vue")
// const ResellerMembershipDowngrade = () => import("@/components/profileNavbar/ResellerMembershipDowngrade.vue");
import moment from "moment";

export default {
  name: "ProfileNavbar",
  components: {
    VueTour,
    ProfileInfo,
    // ResellerJourney,
    TransactionInfo,
    // ResellerTelegramLink,
    // ResellerMembershipDowngrade,
  },
  data() {
    return {
      showConfig: false,
      tourName: "profileTour",
      steps: [],
      callbacks: {
        onPreviousStep: this.onTourPreviousStep,
        onNextStep: this.onTourNextStep,
        onStart: this.onTourStart,
        onStop: this.onTourStop,
      },
      options: tourSettings,
    };
  },
  props: ['visible'],
  computed: {
    userData() {
      return this.$store.getters.getUserData
    },
    resellerData() {
      return this.$store.getters.getResellerData;
    },
    isMembershipActive() {
      return (this.resellerData?.is_active && this.resellerData?.status_verify) || false;
    }
  },
  mounted() {
    this.setProfileTourStep()
    const isShown = this.visible !== undefined || !mobile.isMobile()
    const customerCondition = !cookieMan.get("profileTour").length; //* Return true, If 'profileTour' cookie not exist / still empty
    // const resellerCondition = !cookieMan.get("resellerProfileTour").length; //* Return true, If 'resellerProfileTour' cookie not exist / still empty

    setTimeout(() => {
      //* Run tour guide for customer
      if (isShown && customerCondition) {
        this.showConfig = true
        scroll.scrollActive(false)
        this.$tours['profileTour'].start()
      }
  
      //* Run tour guide for bus. partner with active membership
      // if (isShown && resellerCondition && this.isMembershipActive) {
      //   this.showConfig = true
      //   scroll.scrollActive(false)
      //   this.$tours['profileTour'].start()
      // }
    }, 500);
  },
  methods: {
    openGroup(event) {
      event.target.classList.toggle('caret-active')
    },
    async logout() {
      try {
        const res = await userService.logout();
        if (res.status === 200) {
          toaster.make(
            "You will be redirected to homepage in 3 seconds",
            "success"
          );
          setTimeout(() => {
            this.$router.push("/");
            this.$store.dispatch('resetUserState')
            qbHelper.clearQb();
          }, 2000);
        }
      } catch (e) {
        console.error(e);
      }
    },
    logoutAll() {
      userService.logoutAll();
      toaster.make("Successfully signed out all other devices", "success");
    },
    async shareCatalog(link = this.resellerData.member.shareable_link) {
      await navigator.clipboard.writeText(link);
      toaster.make("Shareable link has been copied to your clipboard", "success");
    },
    formatDate(value) {
      if (value) {
        return moment
          .unix(value / 1000)
          .format("DD MMM YYYY")
          .toString();
      }
      return value;
    },
    // Vue tour methods START
    onTourStart() {
      const offsetPercentage = mobile.isMobile() ? 10 : 50

      this.$firebaseLogEvent("begin_tutorial", {
        tutorial_name: "Profile Tour",
        tutorial_page: "My Profile",
      });

      scroll.scrollTo(this.steps[0].target.split("#")[1], offsetPercentage, undefined, mobile.isMobile() ? 'profile-navbar-window' : '')
      // scroll.scrollActive(false)
    },
    onTourPreviousStep (currentStep) {
      const offsetPercentage = mobile.isMobile() ? 10 : 50
      
      scroll.scrollTo(this.steps[currentStep - 1].target.split("#")[1], offsetPercentage, undefined, mobile.isMobile() ? 'profile-navbar-window' : '')
    },
    onTourNextStep (currentStep) {
      const offsetPercentage = mobile.isMobile() ? 10 : 50

      scroll.scrollTo(this.steps[currentStep + 1].target.split("#")[1], offsetPercentage, undefined, mobile.isMobile() ? 'profile-navbar-window' : '')
    },
    onTourStop() {
      if (this.$refs[this.tourName].isRunning() == false) return;
      
      const currentStep = this.$tours[this.tourName].currentStep
      const isSkip = currentStep < this.steps.length - 1
      if (currentStep < 0) return

      this.$firebaseLogEvent(isSkip ? "skip_tutorial" : "complete_tutorial", {
        tutorial_name: "Profile Tour",
        tutorial_page: "My Profile",
      });
      
      scroll.scrollActive(true)
      // If tour guide stopped in profile page > add cookie profileTour / resellerProfileTour
      // if (/\/profile/.test(this.$router.currentRoute.path)) {
        document.cookie = "profileTour=true; expires=Mon, 31 Dec 2040 12:00:00 GMT; path=/";
        if(this.isMembershipActive) document.cookie = "resellerProfileTour=true; expires=Mon, 31 Dec 2040 12:00:00 GMT; path=/";
      // }
    },
    
    setProfileTourStep() {
      // if(this.resellerData && this.isMembershipActive) {
      //   this.steps.push(
      //     {
      //       target: '#reseller-membership-journey',
      //       header: {
      //         title: 'Membership Journey',
      //       },
      //       content: `Yeay, great job! Ini level kamu sekarang.`,
      //       params: {
      //         placement: mobile.isMobile() ? 'bottom' :'right',
      //         enableScrolling: false,
      //         enableButton: Object.assign({}, this.options.enabledButtons , {}),
      //       }
      //     },
      //     {
      //       target: '#button-membership-info',
      //       header: {
      //         title: 'Membership Info',
      //       },
      //       content: `Cari tau benefit dari setiap level yuk. Klik disini`,
      //       params: {
      //         placement: mobile.isMobile() ? 'bottom' :'right',
      //         enableScrolling: false,
      //         enableButton: Object.assign({}, this.options.enabledButtons , {}),
      //       }
      //     },
      //   )
      // }

      this.steps.push(
        {
          target: '#profile-transaction-info',
          header: {
            title: 'Transaction Info',
          },
          content: `Page ini berisi perjalanan dan pembelajaan kamu di Whizliz.`,
          params: {
            placement: mobile.isMobile() ? 'bottom' :'right',
            enableScrolling: false,
            enableButton: Object.assign({}, this.options.enabledButtons , {}),
          }
        },
      )

      // Additional Business Partner Menu
      // if(this.resellerData && this.isMembershipActive) {
      //   this.steps.push(
      //     {
      //       target: '#reseller-level',
      //       header: {
      //         title: 'WHIZQUAD',
      //       },
      //       content: `Di page ini kamu akan tahu status/level kamu sebagai business partner, dan seberapa banyak misi yang harus atau sudah kamu lakukan.`,
      //       params: {
      //         placement: mobile.isMobile() ? 'bottom' :'right',
      //         enableScrolling: false,
      //         enableButton: this.options.enabledButtons,
      //       }
      //     },
      //     {
      //       target: '#reseller-task',
      //       header: {
      //         title: 'Whiz Activity',
      //       },
      //       content: `Tekan whiz activity untuk memulai aktivitasmu, dan dapatkan hadiahmu!`,
      //       params: {
      //         placement: mobile.isMobile() ? 'bottom' :'right',
      //         enableScrolling: false,
      //         enableButton: this.options.enabledButtons,
      //       }
      //     },
      //     {
      //       target: '#reseller-manage-catalog',
      //       header: {
      //         title: 'Product Catalog',
      //       },
      //       content: `Di page ini kamu bisa mengatur katalog produk`,
      //       params: {
      //         placement: mobile.isMobile() ? 'bottom' :'right',
      //         enableScrolling: false,
      //         enableButton: this.options.enabledButtons,
      //       }
      //     },
      //   )
      // }

      // if(this.resellerData) {
      //   this.steps.push(
      //     {
      //       target: '#reseller-membership',
      //       header: {
      //         title: 'Membership Status',
      //       },
      //       content: `Cek page ini buat tau kamu ada di level apa sebagai business partner :)`,
      //       params: {
      //         placement: mobile.isMobile() ? 'bottom' :'right',
      //         enableScrolling: false,
      //         enableButton: this.options.enabledButtons,
      //       }
      //     },
      //   )
      // }

      // if(this.resellerData && this.isMembershipActive) {
      //   this.steps.push(
      //     {
      //       target: '#reseller-commission',
      //       header: {
      //         title: 'Commission History',
      //       },
      //       content: `Page ini berisi berapa banyak komisi yang sudah kamu lakukan, cek yuk.`,
      //       params: {
      //         placement: mobile.isMobile() ? 'bottom' :'right',
      //         enableScrolling: false,
      //         enableButton: this.options.enabledButtons,
      //       }
      //     },
      //     {
      //       target: '#reseller-order-report',
      //       header: {
      //         title: 'Order report',
      //       },
      //       content: `Cek transaksi dan orderan kamu yang sebelumnya disini.`,
      //       params: {
      //         placement: mobile.isMobile() ? 'bottom' :'right',
      //         enableScrolling: false,
      //         enableButton: this.options.enabledButtons,
      //       }
      //     },
      //     {
      //       target: '#reseller-referral',
      //       header: {
      //         title: 'My Referral',
      //       },
      //       content: `Ga usa ribet buat nginget udah berapa banyak teman yang kamu ajak gabung, cek disini aja :)`,
      //       params: {
      //         placement: mobile.isMobile() ? 'bottom' :'right',
      //         enableScrolling: false,
      //         enableButton: this.options.enabledButtons,
      //       }
      //     }
      //   )
      // }

      // // Basic Menu
      // if (!this.resellerData?.is_active) {
      //   this.steps.push(
      //     {
      //       target: '#button-register-reseller',
      //       header: {
      //         title: 'Become a Business Partner',
      //       },
      //       content: `Mau dapet penghasilan tambahan? Yuk join jadi Business Partner Whizliz sekarang juga.`,
      //       params: {
      //         placement: mobile.isMobile() ? 'bottom' :'right',
      //         enableScrolling: false,
      //         enableButton: Object.assign({}, this.options.enabledButtons , {}),
      //       }
      //     },
      //   )
      // }
      this.steps.push(
        // {
        //   target: '#my-detail',
        //   header: {
        //     title: 'My Detail',
        //   },
        //   content: `Tekan untuk mengetahui setiap detail data yang kamu butuhkan`,
        //   params: {
        //     placement: mobile.isMobile() ? 'bottom' :'right',
        //     enableScrolling: false,
        //     enableButton: Object.assign({}, this.options.enabledButtons , {}),
        //   }
        // },
        {
          target: '#recent-order',
          header: {
            title: 'Recent Order',
          },
          content: `Disini kamu bisa melihat pesanan kamu yang sebelumnya. Jadi lebih mudah deh kalo mau re-purchase!`,
          params: {
            placement: mobile.isMobile() ? 'bottom' :'right',
            enableScrolling: false,
            enableButton: Object.assign({}, this.options.enabledButtons , {}),
          }
        },
        {
          target: '#liz-points',
          header: {
            title: 'My Liz-Points',
          },
          content: `Tempat kamu ngumpulin point sebanyak-banyaknya! Kalo udah banyak bisa dipake belanja lagi loh :)`,
          params: {
            placement: mobile.isMobile() ? 'bottom' :'right',
            enableScrolling: false,
            enableButton: Object.assign({}, this.options.enabledButtons , {}),
          }
        },
        {
          target: '#profile-review',
          header: {
            title: 'My Reviews',
          },
          content: `Page yang ngingetin kamu tentang produk" yang uda pernah kamu review.`,
          params: {
            placement: mobile.isMobile() ? 'bottom' :'right',
            enableScrolling: false,
            enableButton: Object.assign({}, this.options.enabledButtons , {}),
          }
        },
        {
          target: '#edit-profile',
          header: {
            title: 'Edit Profile',
          },
          content: `Cukup tekan aja untuk mengubah profile kamu.`,
          params: {
            placement: mobile.isMobile() ? 'bottom' :'right',
            enableScrolling: false,
            enableButton: Object.assign({}, this.options.enabledButtons , {}),
          }
        },
        {
          target: '#change-password',
          header: {
            title: 'Change Password',
          },
          content: `Cukup tekan aja untuk mengubah password kamu.`,
          params: {
            placement: mobile.isMobile() ? 'bottom' :'right',
            enableScrolling: false,
            enableButton: Object.assign({}, this.options.enabledButtons , {}),
          }
        },
        {
          target: '#my-address',
          header: {
            title: 'My Address',
          },
          content: `Tulis alamat kamu disini, biar barang kamu sampai ke tempat yang tepat!`,
          params: {
            placement: mobile.isMobile() ? 'bottom' :'right',
            enableScrolling: false,
            enableButton: Object.assign({}, this.options.enabledButtons , {}),
          }
        },
      )
    }
    // Vue tour methods END
  },
};
</script>

<style scoped>
* {
  transition: unset;
}
/* .router-link-active {
  background-color: #ffefd7;
} */
.router-link-active .side-menu-item {
  font-weight: 700 !important;
}
.router-link-active,
.router-link-active a:hover {
  color: #d89700 !important;
  text-decoration: none !important;
}
.router-link-active img.act,
.router-link-active img.act {
  display: block !important;
}
.router-link-active img.idle,
.router-link-active img.idle {
  display: none !important;
}
.w28h28 {
  width: 28px;
  height: 28px;
}
</style>
